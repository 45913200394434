<template>
  <v-card class="mr-8 mb-8" max-width="300" v-on="$listeners">
    <!-- <v-img
      class="white--text align-end"
      height="200px"
      src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"
    >
      <v-card-title>{{ $attrs.tripdata.day }}</v-card-title>
    </v-img> -->

    <v-img
      class="white--text align-end"
      height="200px"
      width="400px"
      v-if="$attrs.tripdata.images"
      :src="$attrs.tripdata.images[0].fileUrl"
      :aspect-ratio="1 / 1"
      ><v-card-title>{{ $attrs.tripdata.day }}</v-card-title></v-img
    >

    <v-card-subtitle class="pb-0" v-if="$attrs.tripdata.selectedCityName">
      {{ $attrs.tripdata.selectedCityName }}</v-card-subtitle
    >
    <v-card-subtitle v-else style="" class="error--text font-weight-normal">
      <p class="xtag-text">Мэдээлэл, оруулж оноо цуглуулаx</p></v-card-subtitle
    >

    <v-card-text class="text--primary">
      <div>{{ $attrs.tripdata.Description }}</div>
      <h3>{{ $attrs.tripdata.name }}</h3>
    </v-card-text>

    <!-- <v-card-actions>
      <v-btn color="orange" text> Share </v-btn>

      <v-btn color="orange" text> Explore </v-btn>
    </v-card-actions> -->
  </v-card>
</template>
<script>
export default {};
</script>